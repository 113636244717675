<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Memberships</h1>
        </v-col>
        <v-col>
          <div class="input-container d-flex align-center">
            <v-text-field
              label="Search Memberships"
              v-model="searchTerm"
              outlined
              dense
              hide-details
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-menu
              offset-y
              left
              transition="slide-y-transition"
              :close-on-click="searchFilters.menu.closeOnClick"
              :close-on-content-click="searchFilters.menu.closeOnContentClick"
            >
              <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-btn
                      icon
                      v-on="{ ...onTooltip, ...onMenu }"
                      v-bind="attrsMenu"
                    >
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>
                  </template>
                  <span>Filter Search</span>
                </v-tooltip>
              </template>
              <v-card
                elevation="0"
                color="grey lighten-5"
                style="min-width: 200px"
              >
                <v-card-subtitle class="d-flex align-center">
                  <div class="font-weight-bold">Filters</div>
                  <v-spacer></v-spacer>
                  <v-btn outlined x-small>Reset</v-btn>
                </v-card-subtitle>
              </v-card>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  v-on="on"
                  @click="$refs.membershipDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Membership</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-data-table
            :headers="tableHeaders"
            :items="memberships"
            no-data-text="No Memberships"
          >
            <template v-slot:item.membership_type="{ item }">
              <v-chip small label>{{ item.membership_type.title }}</v-chip>
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip
                v-if="new Date().toISOString().substring(0, 10) > item.valid_to"
                small
                label
                color="red"
                text-color="white"
                >Expired</v-chip
              >
              <v-chip
                v-else
                small
                label
                :color="getSeasonPassStatusColor(item.status)"
                >{{ getSeasonPassStatusLabel(item.status) }}</v-chip
              >
            </template>

            <template v-slot:item.valid_to="{ item }">
              <div v-if="item.valid_to">
                {{ item.formatted_dates.valid_to }}
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    :to="{
                      name: 'module-csear-memberships-individual',
                      params: { membershipId: item.id },
                    }"
                  >
                    <v-icon x-small>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="2">
          <v-card class="text-center blue lighten-4 mb-6" outlined>
            <v-card-text>
              <div class="text-subtitle-1">Total</div>
              <div class="text-h4">{{ total_memberships.total }}</div>
            </v-card-text>
          </v-card>
          <!-- <v-card class="text-center green lighten-4 mb-6" outlined>
            <v-card-text>
              <div class="text-subtitle-1">
                New<br />
                This Month
              </div>
              <div class="text-h4">{{ total_memberships.month }}</div>
            </v-card-text>
          </v-card> -->
        </v-col>
      </v-row>
    </v-container>
    <membership-dialog ref="membershipDialog" />
  </div>
</template>

<script>
import MembershipDialog from "./components/MembershipDialog.vue";
import { csear } from "../../mixins";
export default {
  components: { MembershipDialog },

  mixins: [csear],

  data() {
    return {
      breadcrumbs: [
        {
          text: "Memberships",
          disabled: true,
        },
      ],

      searchTerm: "",
      searchFilters: {
        menu: {
          open: false,
          closeOnClick: true,
          closeOnContentClick: false,
        },
      },
      tableHeaders: [
        { text: "Customer Name", value: "customer.full_name" },
        { text: "Status", value: "status" },
        { text: "Expiry Date", value: "valid_to" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    memberships() {
      let memberships = this.$store.getters["csear/membershipsStore/all"];

      if (this.searchTerm !== "") {
        memberships = memberships.filter((t) => {
          const customerName = t.customer.full_name.toLowerCase();

          const searchTerm = this.searchTerm.toLowerCase();

          return customerName.includes(searchTerm);
        });
      }

      return memberships;
    },

    total_memberships: function () {
      let memberships = this.$store.getters["csear/membershipsStore/all"];
      let date = new Date();

      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      var fourWeeksInFuture = new Date(new Date().setDate(date.getMonth() + 1));

      let memberships_array = {};

      // let total_valid = memberships.filter((o) => {
      //   const validTo = new Date(o.valid_to);
      //   const validFrom = new Date(o.valid_from);
      //   return validFrom <= date && validTo >= date;
      // });

      let total = memberships;

      let memberships_in_month = memberships.filter((o) => {
        const itemDate = new Date(o.created_at);
        return itemDate >= firstDay && itemDate <= lastDay;
      });

      let expiring = memberships.filter((o) => {
        const validTo = new Date(o.valid_to);
        const validFrom = new Date(o.valid_from);
        return validFrom <= date && validTo >= fourWeeksInFuture;
      });

      memberships_array["total"] = total.length;
      memberships_array["month"] = memberships_in_month.length;
      memberships_array["expiring"] = expiring.length;

      return memberships_array;
    },
  },
};
</script>
